import { PageHeader } from '@components/compound/PageHeader';
import { NextSeo } from 'next-seo';
import { OpenGraph } from 'next-seo/lib/types';
import { FC, ReactNode } from 'react';

interface IKsLayoutProps {
  children: ReactNode;
  title?: string;
  description?: string;
  hasPageHeader?: boolean;
  hasPageHeaderTitle?: boolean;
  pageHeaderTitle?: string;
  pageHeaderBackground?: string;
  breadcrumbs?: ReactNode[];
  breadcrumbsColor?: string;
  hasOverlay?: boolean;
  colorTitle?: string;
  og?: OpenGraph;
  seo?: any;
}

const KsLayout: FC<IKsLayoutProps> = ({
  children,
  seo,
  hasPageHeader = false,
  pageHeaderTitle,
  pageHeaderBackground,
  breadcrumbs,
  hasOverlay = false,
  breadcrumbsColor,
  colorTitle,
}) => {
  return (
    <>
      {seo && <NextSeo {...seo} />}

      {hasPageHeader && (
        <PageHeader
          background={pageHeaderBackground}
          title={pageHeaderTitle}
          breadcrumbs={breadcrumbs}
          overlay={hasOverlay}
          color={breadcrumbsColor}
          colorTitle={colorTitle}
        />
      )}

      <div className="ks-content">{children}</div>
    </>
  );
};

export default KsLayout;
