import useCheckErrorImage from '@/hooks/useCheckErrorImage';
import { Breadcrumbs } from '@mui/material';
import classNames from 'classnames';
import { map } from 'lodash';
import { FC, ReactNode } from 'react';
import pageHeaderStyles from '@scss/components/page-header.scss';

interface IPageHeaderProps {
  title?: string;
  breadcrumbs?: ReactNode[];
  background?: string;
  overlay?: boolean;
  color?: 'primary' | 'secondary' | 'black' | 'white' | string;
  colorTitle?: 'primary' | 'secondary' | 'black' | 'white' | string;
}

export const PageHeader: FC<IPageHeaderProps> = ({
  title,
  breadcrumbs,
  background,
  overlay = false,
  color,
  colorTitle,
}) => {
  const [isError, imageUrl] = useCheckErrorImage(background || '');

  return (
    <>
      <style jsx>{pageHeaderStyles}</style>
      <div
        className="ks-page-header"
        style={{
          backgroundImage: `url(${!isError ? imageUrl : 'none'})`,
        }}
      >
        {overlay && <div className="overlay"></div>}
        <div className="ks-container wrapper">
          {title && <h1 className={classNames('title', { [`-${color}`]: colorTitle })}>{title}</h1>}
          <Breadcrumbs className={classNames('breadcrumbs', { [`-${color}`]: color })}>
            {map(breadcrumbs, (component) => component)}
          </Breadcrumbs>
        </div>
      </div>
    </>
  );
};
