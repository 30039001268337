import { defaultImages } from "@utils/constants";
import { useEffect, useState } from "react";

export default function useCheckErrorImage(
  url: string,
  defaultImage = defaultImages.ERROR
) {
  const [isError, setIsError] = useState(false);
  const [imageUrl, setImageUrl] = useState<string | null>(null);

  useEffect(() => {
    if (!url) {
      setIsError(true);
      setImageUrl(defaultImage);
      return;
    }

    const img = new Image();
    img.onerror = () => {
      setIsError(true);
      setImageUrl(defaultImage);
    };
    img.onload = () => {
      setImageUrl(url);
    };
    img.src = url;
  }, [url, defaultImage]);

  return [isError, imageUrl];
}
